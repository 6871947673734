import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {Incident} from '../../pages/incident/incident';

@Injectable()
export class IncidentService {

  constructor(private httpClient: HttpClient) {
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Incident>>(`${environment.api_url}/api/incidents`);
  }

  findById(id: string) {
    return this.httpClient.get<Incident>(`${environment.api_url}/api/incidents/${id}`);
  }

  newIncident(payload: any) {
    return this.httpClient.post<Incident>(`${environment.api_url}/api/incidents`, payload);
  }

  putIncident(id: string, payload: any) {
    return this.httpClient.put<Incident>(`${environment.api_url}/api/incidents/${id}`, payload);
  }

  deleteIncident(id: string) {
    return this.httpClient.delete<Incident>(`${environment.api_url}/api/incidents/${id}`);
  }

  getByResidentId(id: string) {
    return this.httpClient.get<PagedResponse<Incident>>(`${environment.api_url}/api/incidents?residentId=${id}`);
  }
  getByStaffId(id: string) {
    return this.httpClient.get<PagedResponse<Incident>>(`${environment.api_url}/api/incidents?staffId=${id}`);
  }
  findByQuery(qs: string) {
    return this.httpClient.get<PagedResponse<Incident>>(`${environment.api_url}/api/incidents?query=${qs}`);
  }
  incidentHandover(value: any) {

    return this.httpClient.post<any>(`${environment.api_url}/api/incident-handover`, value);
  }
  incidentUpdateHandover(id, value: any) {

    return this.httpClient.put<any>(`${environment.api_url}/api/incident-handover/${id}`, value);
  }
  getAllHandover() {

    return this.httpClient.get<any>(`${environment.api_url}/api/incident-handover`);
  }
  deleteHandover(id: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/incident-handover/${id}`);
  }
}
