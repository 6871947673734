import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
import { SubscriptionService } from '../../@core/services/subscription.service';
import { OrganizationService } from '../../@core/services/organization.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'ngx-orgform',
  templateUrl: './orgform.component.html',
  styleUrls: ['./orgform.component.scss'],
})
export class OrgformComponent implements OnInit {

  myForm: FormGroup;
  showPlans: any;
  CheckoutPayload: any = {};
  UpdateOrgPayload: any = {};
  constructor(private formBuilder: FormBuilder,
    // private router: Router,
    private subsservice: SubscriptionService,
    private organizationService: OrganizationService) { }
  submitted = false;
  test: any = 'test';
  canRouteTodashboard = false;
  organizationDetails: any = {};
  OrgId: any;
  pageloaded: any = 0;
  ngOnInit() {
    if (localStorage.getItem('reloadPage') === 'true') {
      location.reload();
      localStorage.removeItem('reloadPage');
    }


    // this.canRouteTodashboard=true
    document.getElementById('nb-global-spinner').style.display = 'none';
    this.myForm = this.formBuilder.group({
      name: ['', Validators.required],
      proprietorName: [''],
      companyName: [''],
      contactNumber: [''],
      numRegisteredBeds: ['', Validators.required],
      managerName: [''],
      address: ['', Validators.required],
      managerContactNumber: [''],
      phoneNumber: [''],
      nameOfPSC: [''],
      abnNumber: [''],
      faxNumber: [''],
      chemistDetails: [''],
      email: ['', [Validators.required, Validators.email]],
      website: [''],
    });
    this.organizationService.findCurrentOrg().subscribe((res) => {

      this.OrgId = res.id;

      // Update the form controls with the API response
      this.myForm.patchValue({
        name: res.name,
        proprietorName: res.proprietorName,
        companyName: res.companyName,
        contactNumber: res.contactNumber,
        numRegisteredBeds: res.numRegisteredBeds,
        managerName: res.managerName,
        address: res.address,
        managerContactNumber: res.managerContactNumber,
        phoneNumber: res.phoneNumber,
        nameOfPSC: res.nameOfPSC,
        faxNumber: res.faxNumber,
        abnNumber: res.abnNumber,
        chemistDetails: res.chemistDetails,
        email: res.email,
        website: res.website,
      });
    });


  }

  get f() {
    return this.myForm.controls;
  }
  submitForm() {
    if(this.myForm.controls.numRegisteredBeds.value<=14){
      this.CheckoutPayload.plan='Small Plan'
    }
    else if(this.myForm.controls.numRegisteredBeds.value>14 && this.myForm.controls.numRegisteredBeds.value<=29){
      this.CheckoutPayload.plan='Medium Plan'
    }
    else if(this.myForm.controls.numRegisteredBeds.value>=30 && this.myForm.controls.numRegisteredBeds.value<=50){
      this.CheckoutPayload.plan='Large Plan'
    }
    else if(this.myForm.controls.numRegisteredBeds.value>=50){
      this.CheckoutPayload.plan='X-Large Plan'
    }
    this.submitted=true
    
    this.organizationDetails=this.myForm.value
    const plan=this.CheckoutPayload.plan
    localStorage.setItem('plan',plan)

    
    this.organizationDetails.plan=this.CheckoutPayload.plan
    this.organizationService.updateCurrentOrg(this.OrgId,this.organizationDetails)
    .subscribe((res)=>{
    })
    if (this.myForm.valid) {
      
      this.CheckoutPayload.email=this.organizationDetails.email
      this.CheckoutPayload.paymentSuccessUrl=`${environment.server_url}/#/subscribe/success`
      this.CheckoutPayload.paymentCancelUrl=`${environment.server_url}/#/subscribe/organizationdetails`
      this.subsservice.onCheckoutStripe(this.CheckoutPayload).subscribe((res) => {

        const response = res as { url: string }; // Type assertion
        const paymentUrl = response.url;


        window.location.href = paymentUrl;
      });
      // Form is valid, you can perform form submission logic here
      // this.router.navigate(['/pages/dashboard'])
    } else {
      // Form is invalid, handle validation errors

    }
  }

}
