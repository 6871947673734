
   <div class="containerr">
       <div class="form-containerr">
           
           <form  [formGroup]="myForm" (ngSubmit)="submitForm()">
               <h1 class="label" style="color: white;">Confirm details</h1>
               <div class="row">
               
               
               <!-- BUSINESS NAME -->
               <div class="col-6 Field-container" >
                   <div class="form-group">
                       <label for="inputName" class="label col-form-label">Business Name <span
                        style="color: white;">*</span></label>
                        <input type="text" formControlName="name" class="form-control" nbInput fullWidth id="inputName"
                        placeholder="Business Name" 
                        >
                        <small style="color: white;" *ngIf="f.name.errors?.required && f.name?.touched">Business Name is required</small>
                        
                        
                    </div>
                </div>
                <!-- PROPRIETOR NAME -->
                <div class="col-6 Field-container">
                    <div class="form-group">
                        <label for="inputproprietorName" class="label col-form-label">Proprietor Name</label>
                        <input type="text" formControlName="proprietorName" class="form-control" nbInput fullWidth
                        id="inputproprietorName" placeholder="proprietorName"
                        >
                        
                    </div>
                </div>
                
                <!-- COMPANY NAME -->
                <div class="col-6 Field-container">
                    <div class="form-group">
                        <label for="companyName" class="label col-form-label">Company name</label>
                        <input type="text" formControlName="companyName" class="form-control" nbInput fullWidth id="inputName"
                        placeholder="Company Name" >
            
        </div>
    </div>
    <!-- PROPRIETOR CONTACT NUMBER -->
    <div class="col-sm-6 Field-container">
        <div class="form-group">
            <label for="inputcontactNumber" class="label col-form-label"> Proprietor Contact Number</label>
            <input type="text" formControlName="contactNumber" class="form-control"
            placeholder="Proprietor Contact Number" >
            
        </div>
    </div>
    <!-- REGISTERED BEDS -->
    <div class="col-6 Field-container">
        <div class="form-group">
            <label for="numRegisteredBeds" class="label col-form-label">Registered Beds<span
                 style="color: white;">*</span></label>
                <input type="number" formControlName="numRegisteredBeds" class="form-control" nbInput fullWidth
                id="inputName" placeholder="Registered Beds"
                >
                <small style="color: white;" *ngIf="f.numRegisteredBeds?.errors?.required && f.numRegisteredBeds?.touched">Registered Beds is required</small>
                
            </div>
        </div>
        <!-- MANAGER NAME -->
        <div class="col-6 Field-container">
            <div class="form-group">
                <label for="inputmanagerName" class="label col-form-label">Manager Name </label>
                <input type="text" formControlName="managerName" class="form-control"
                placeholder="Manager Name">
                
            </div>
        </div>
        <!-- ADDRESS -->
        <div class="col-6 Field-container">
            <div class="form-group">
                <label for="address" class="label col-form-label">Address<span  style="color: white;">*</span></label>
                <input type="text" formControlName="address" class="form-control" nbInput fullWidth id="inputName"
                placeholder="Address">
                
            </div>
            <small style="color: white;"  *ngIf="f.address?.errors?.required && f.address?.touched">address is required</small>
        </div>
        <!-- MANAGER CONTACT NUMBER -->
        <div class="col-sm-6 Field-container">
            <div class="form-group">
                <label for="inputmanagerContactNumber" class="label col-form-label">Manager Contact Number</label>
                <input type="text" placeholder="Manager Contact Number" formControlName="managerContactNumber" class="form-control"
                >
                
            </div>
        </div>
        <!--  PHONE NUMBER -->
        <div class="col-sm-6 Field-container">
            <div class="form-group">
                <label for="inputphoneNumber" class="label col-form-label">Phone Number</label>
                <input type="text" placeholder="Phone Number" formControlName="phoneNumber" class="form-control"
                >
            </div>
        </div>
        <!-- PSC NUMBER -->
        <div class="col-sm-6 Field-container">
            <div class="form-group">
                <label for="inputnameOfPSC" class="label col-form-label">PSC Name</label>
                <input type="text" formControlName="nameOfPSC" class="form-control"
                placeholder="PSC Name">
                
            </div>
        </div>
        <!-- FAX NUMBER -->
        <div class="col-sm-6 Field-container">
            <div class="form-group">
                <label for="inputfaxNumber" class="label col-form-label">Fax Number</label>
                <input type="text" placeholder="Fax Number" formControlName="faxNumber" class="form-control"
                >
                
            </div>
        </div>
        <div class="col-sm-6 Field-container">
            <div class="form-group">
                <label for="inputabnNumber" style="margin-left: -13px;" class="label col-sm-12 col-form-label">ABN/ACN</label>
                <input type="text" placeholder="ABN/ACN" formControlName="abnNumber" class="form-control"
                >
                
            </div>
        </div>
        <!-- CHEMIST DETAILS -->
        <div class="col-sm-6 Field-container">
            <div class="form-group">
                <label for="inputchemistDetails" class="label col-form-label">Chemist Details</label>
                <textarea rows="5" nbInput fullWidth shape="rectangle" formControlName="chemistDetails" class="form-control"
                ></textarea>
                
        </div>
    </div>
    <!-- EMAIL -->
    <div class="col-sm-6 Field-container">
        <div class="form-group">
            <label for="inputEmail" class="label col-form-label">Email<span
                style="color: white;">*</span> </label>
                <input placeholder="Email" type="email" formControlName="email" class="form-control"
                >
                <small style="color: white;" *ngIf="f.email.errors?.required && f.email?.touched">Email is required</small>
                <small style="color: white;"  *ngIf="f.email.errors?.email  && f.email?.touched">Please Enter a Valid Email</small>
                
            </div>
        </div>
        <!-- WEBSITE -->
        <div class="col-sm-6 Field-container">
            <div class="form-group">
                <label for="inputwebsite" class="label col-form-label">Website</label>
                <input type="text" placeholder="Website" formControlName="website" class="form-control"
                >
                
            </div>
        </div>
        <!-- Submit Button -->
        <div class="col-12 mt-3 text-center">
            <button type="submit"  style="width: 120px; background-color: rgb(160, 211, 253);
            border-color: rgb(160, 211, 253);"  class="btn w-40 button__text"
            status="primary">Submit</button>
        </div>
    </div>
</form>
</div>
<div  >

    <img style="position: absolute; bottom: -408px; left: 0px;" src="../../../../assets/images/srs_wht.png"  alt="Logo" id="logoImage">
</div>
</div>

