<div class="header-container">
  <div class="logo-container">
    <a
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small" >

    <nb-action class="control-item  no-border"></nb-action>
    <nb-action
      class="control-item  no-border"
      icon="email-outline"
      matRipple
      [badgeText]="messageCountData?.unreadMessageCount"
      badgePosition="bottom right"
      badgeStatus="warning"
      [nbContextMenu]="privateMessages"
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      
    ></nb-action>
 
      <!-- <nb-action icon="bell-outline"
      [nbContextMenu]="notificationContextItems"
      >
       
        <ng-template #nbContextMenu let-items="items">
          <ng-container *ngFor="let item of items">
            <div [innerHTML]="notificationContextItems.title"></div>
          </ng-container>
        </ng-template>
      </nb-action> -->
    
    <nb-action 
      #notificationAction
      class="control-item no-border bell_icon"
      icon="bell-outline"
      badgeStatus="danger"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      [nbContextMenu]="notificationContextItems"
    >
    <ng-template #nbContextMenu let-items="items">
      <ng-container *ngFor="let item of items">
        <div (click)="onClicknot()" [innerHTML]="notificationContextItems"></div>
      </ng-container>
    </ng-template>
  </nb-action>
    
    <nb-action

      class="user-action no-border"
      *nbIsGranted="['view', 'user']"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.firstName"
               [picture]="profilePicUrl"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
