import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddsubscriptionComponent } from './addsubscription/addsubscription.component';
import { StripeWindowComponent } from './stripe-window/stripe-window.component';
import { StripeSuccessComponent } from './stripe-success/stripe-success.component';
import { TcpageComponent } from './tcpage/tcpage.component';
import { OrgformComponent } from './orgform/orgform.component';
import { RenewComponent } from './renew/renew.component';

const routes: Routes = [
  {path: '', component: AddsubscriptionComponent},
  {path: 'stripe-payment', component: StripeWindowComponent},
  {path: 'success', component: StripeSuccessComponent},
  {path: 'terms-and-condition', component: TcpageComponent},
  {path: 'organizationdetails', component: OrgformComponent},
  {path: 'renew', component: RenewComponent},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscribeRoutingModule { }
